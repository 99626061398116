.search-bar {
  position: relative;
}

.search-bar .form-control {
  height: 47px;
}

.search-bar .form-control:focus {
  box-shadow: none;
  border: 1px solid #000;
}

/* Container for the search results that appears as a card */
.search-results-card {
  position: absolute;
  top: 100%; /* Positioned right below the input field */
  left: 0;
  right: 0; /* Ensures the container stretches to the width of its parent */
  z-index: 10; /* Higher than other content but below modals/dropdowns */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
  border-radius: 0.25rem; /* Optional: Rounds the corners of the card */
  overflow: hidden; /* Ensures content doesn't overflow the card's rounded corners */
  background-color: #fff; /* Ensures background is white */
}

/* Styles for the buttons within the card */
.search-results-card .btn {
  width: 100%; /* Makes button stretch the full width */
  text-align: left; /* Aligns text to the start */
}

.search-results-card .btn:hover {
  background-color: #f15c25;
  color: white;
}

/* Styling for labels */
.search-results-card .card-body .text-center {
  font-weight: bold;
  font-style: normal;
  display: block; /* Makes label take up the full width */
  text-align: center; /* Centers the label text */
  margin-bottom: 0.5rem; /* Adds space below the label */
}
