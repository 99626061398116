.player-info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  width: 80%;
  max-width: 1280px;
  margin: auto;
}

.player-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f15c25;
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  width: calc(50% - 40px);
  box-sizing: border-box;
  transition: width 0.2s;
}

.player-photo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-photo img,
.player-photo PlayerImage {
  height: 100px; /* Smaller size to match the design */
  width: 100px; /* Keep it consistent with height for a circle */
  margin-right: 20px; /* Adjusted margin to align with the text */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.player-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  overflow: hidden;
}

.player-info h2 {
  text-align: left;
  color: #e6e6e6;
  margin: 0 0 10px 0;
  width: 100%;
}

.detail {
  display: flex;
  flex-direction: row; /* Align details horizontally */
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2px 0; /* Reduced padding for a tighter layout */
  font-size: 0.9rem; /* Slightly smaller font size */
  line-height: 1.2; /* Adjust line height for better readability */
}

.detail-label {
  font-weight: bold;
  margin-right: 5px; /* Reduced margin to bring label and value closer */
}

@media (max-width: 1100px) {
  .player-info-container {
    display: column; /* Use grid layout for small screens */
    grid-template-columns: repeat(4, 1fr); /* Create four equal columns */
    gap: 10px; /* Set gap between grid items */
    width: 100%;
  }

  .player-card {
    width: 100%; /* Make the card full width on small screens */
    margin: 10px 0; /* Adjust margin for vertical stacking */
    flex-direction: row;
  }

  /* Adjust image container to center on small screens if needed */
  .player-photo {
    justify-content: center;
  }

  /* Adjust details container to take full width */
  .player-content {
    width: 100%;
    margin-left: 3%;
  }

  .player-details {
    width: 100%;
  }

  /* Stack details vertically and align items to start */
  .detail {
    /* Ensure each detail spans one cell in the grid */
    grid-column: span 1;
    grid-row: span 1;
  }

  /* Adjustments for player photo to align with the new grid layout of details */
  .player-photo img,
  .player-photo PlayerImage {
    margin-right: 0; /* Remove margin since the photo is not next to text in the grid */
    margin-bottom: 10px; /* Space between image and details grid */
    align-self: start; /* Align the image to the start of the card */
    justify-self: center; /* Center the image horizontally */
    height: 80px; /* Adjust size as needed */
    width: 80px; /* Adjust size as needed */
  }
}
