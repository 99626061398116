/* Modal Overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal Container */
.modalContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 60%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1000;
}

/* Modal-specific Text Styles */
.modalContainer * {
  color: #333; /* Apply dark text color only inside modal */
}

/* Modal Header */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Close Button */
.modalCloseButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Modal Body */
.modalBody {
  margin-bottom: 20px;
}

/* Modal Footer */
.modalFooter {
  display: flex;
  justify-content: flex-end;
}

/* Form Elements */
.formGroup {
  margin-bottom: 15px;
}

.modalContainer label,
.modalContainer p,
.modalContainer h1,
.modalContainer h2,
.modalContainer h3,
.modalContainer h4,
.modalContainer h5,
.modalContainer h6 {
  color: #333; /* Ensure dark text color within modal elements */
}

.modalContainer input[type="text"],
.modalContainer input[type="number"],
.modalContainer textarea,
.modalContainer select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  background-color: #fff;
}

.modalContainer input[type="checkbox"] {
  margin-right: 10px;
}

.modalContainer textarea {
  resize: vertical;
}

/* Button Styles */
.modalContainer button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.modalContainer button:focus {
  outline: none;
}

.modalFooter button {
  margin-left: 10px;
}

.modalSaveButton {
  background-color: #007bff;
  color: white;
}

.modalCloseButton {
  background-color: #6c757d;
  color: white;
}

/* Card Container */
.d-flex.flex-wrap.justify-content-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Card Styling */
.card {
  width: 100%; /* Make cards take full width of the column */
  max-width: 1000px; /* Set a maximum width for each card */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.card .card-header,
.card .card-body,
.card .card-footer {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ccc;
  background-color: #f7f7f7;
}

.card-body {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: stretch;
  padding: 15px;
}

.card-footer {
  text-align: center;
  padding: 15px;
  border-top: 1px solid #ccc;
  background-color: #f7f7f7;
}

.card img {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
}

.card-title {
  word-break: break-word;
  font-size: 1.25rem;
}

.card-subtitle {
  color: #6c757d;
}

.pr-2 {
  padding-right: 10px;
  border-right: 1px solid #ccc;
}

.mt-3.mt-md-0 {
  margin-top: 20px;
}

.m-1 {
  margin: 5px;
}

/* Ensuring Consistent Sizing Inside Card Sections */
.card-body .flex-grow-1, .card-body .mt-3.mt-md-0 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px; /* Set a consistent minimum height */
}

.card-body .flex-grow-1 .card, .card-body .mt-3.mt-md-0 .card {
  flex: 1;
  margin-bottom: 10px;
  padding: 10px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.card-body .flex-grow-1 .card:last-child, .card-body .mt-3.mt-md-0 .card:last-child {
  margin-bottom: 0;
}

.card-body .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body .card .card-text {
  flex-grow: 1;
}

/* PostCardRequirement Specific Styling */
.card-body .card .card-subtitle {
  font-size: 1rem;
  font-weight: bold;
}

.card-body .card .card-text {
  margin-top: 5px;
}

/* PostCardSection Specific Styling */
.card-body .card.mb-3 {
  height: auto;
  min-height: 150px; /* Set a minimum height for consistency */
}
