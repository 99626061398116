.stats-table-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.stats-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px; /* Adjust based on your layout */
}

.stats-table th,
.stats-table td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd; /* Light grey border */
}

.stats-table th {
  background-color: #e25623;
  color: white;
}

.stats-table tr:hover {
  background-color: #ddd;
}

.th-map,
.th-picked,
.th-banned,
.th-chosenT,
.th-chosenCT {
  width: 100px !important;
}
