.nav-pills {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* For momentum scrolling on mobile devices */
}

.nav-pills .nav-link {
  flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
}

.seasons-scrollable {
  max-width: 100%; /* Adjust this as necessary */
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap !important;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* For Webkit browsers like Chrome/Safari */
.seasons-scrollable::-webkit-scrollbar {
  height: 12px; /* Adjusts the scrollbar height for horizontal scrolling */
}

.seasons-scrollable::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.seasons-scrollable::-webkit-scrollbar-thumb {
  background: #888;
}

.seasons-scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}
