.season-medals {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px; /* Adjust as needed */
}

.table-header {
  font-size: 25px; /* or whatever size you prefer */
  /* Add any other styling you want for your table headers here */
}

.season-header {
  vertical-align: middle; /* Add this to align the text vertically in the cell */
  padding-top: 34px; /* Adjust as needed */
  font-size: 19px;
}

.medal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px; /* This sets the width of the medal */
  height: 110px; /* This sets the height of the medal */
  /* Remove border-radius to eliminate the circle shape */
  color: black;
  font-weight: bold;
  font-size: 14px;
  margin: 0 1px; /* Adjust spacing between medals */
  padding: 1px;
  text-align: center;
  background-color: transparent; /* Make background transparent */
}

.gold {
  background-color: rgb(255, 215, 0);
}

.red {
  background-color: rgb(235, 75, 75);
}

.pink {
  background-color: rgb(211, 46, 230);
}

.purple {
  background-color: rgb(136, 71, 255);
}

.blue {
  background-color: rgb(75, 105, 255);
}

.lightblue {
  background-color: rgb(94, 152, 217);
}

.white {
  background-color: rgb(176, 195, 217);
  color: black;
}

.grey {
  background-color: grey;
}
