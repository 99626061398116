/* PlayerSlot.module.css */

.flipCard {
    perspective: 1000px;
    height: 100%; /* Ensure the card's height is consistent */
}

.flipCardInner {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    position: relative;
}

.flipped .flipCardInner {
    transform: rotateY(180deg);
}

.flipCardFront, .flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.flipCardFront {
    transform: rotateY(0deg);
}

.flipCardBack {
    transform: rotateY(180deg);
    padding: 10px; /* Add padding for better text spacing */
    text-align: center;
    overflow: hidden; /* Prevent text overflow */
    justify-content: flex-start; /* Start aligning items from the top */
}

/* PlayerSlot.module.css */


/* PlayerSlot.module.css */

.ShinyGold {
    border: 4px solid gold;
    background-color: rgba(255, 215, 0, 0.3); /* Stronger light gold background */
}

.Gold {
    border: 4px solid gold;
    background-color: rgba(255, 215, 0, 0.3); /* Stronger light gold background */
}

.ShinySilver {
    border: 4px solid silver;
    background-color: rgba(192, 192, 192, 0.3); /* Stronger light silver background */
}

.Silver {
    border: 4px solid silver;
    background-color: rgba(169, 169, 169, 0.3); /* Stronger light gray background */
}

.Bronze {
    border: 4px solid sienna;
    background-color: rgba(160, 82, 45, 0.3); /* Stronger light sienna background */
}
.Gray {
    border: 4px solid gray;
    background-color: rgba(128, 128, 128, 0.3); /* Semi-transparent gray background */    
}

/* Shiny effect for images */
.card-body {
    padding: 10px; /* Add a small amount of padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.card-body .text-center {
    margin: 10px 0; /* Add some margin between image and text */
}

.card-footer {
    margin-top: 10px; /* Ensure consistent space above the button */
    padding: 0; /* Remove padding from the footer */
    width: 100%; /* Ensure the button takes the full width */
}



/* Ensures no extra padding at the bottom of the card */
.card {
    padding: 0;
    margin: 0;
}

.shinyEffect {
    position: relative;
    overflow: hidden;
}

.shinyEffect::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.2) 75%);
    background-size: 200% 200%;
    animation: glimmer 3s infinite;
}

/* Keyframe animation for the glimmer effect */
@keyframes glimmer {
    0% {
        background-position: -200%;
    }
    50% {
        background-position: 200%;
    }
    100% {
        background-position: -200%;
    }
}