.edit-team-container .player-card {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #FF5722; /* Adjust color as needed */
    border-radius: 10px;
    width: 100%; /* Ensure the card takes full width of the column */
}

.edit-team-container .remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.edit-team-container .player-image {
    width: 100%; /* Ensure the image container takes full width of the card */
    max-width: 150px; /* Adjust max-width as needed */
    margin: 0 auto;
}

.edit-team-container .player-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.edit-team-container .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-team-container .col-3 {
    padding-left: 5px; /* Reduce left padding */
    padding-right: 5px; /* Reduce right padding */
    display: flex;
    justify-content: center;
}

.edit-team-container .card-title {
    margin-top: 10px;
    font-size: 16px;
}
