.map-selection {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start of the flex container */
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto; /* Add vertical scroll when content overflows */
}

.map-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto; /* Adjust width as needed */
  height: 100px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
  position: relative;
  color: white; /* Text color */
  margin-bottom: 20px; /* Space for the overlay logo */
  margin-right: 10px; /* Space between map boxes */
}

.map-image {
  width: auto;
  height: 100%;
  display: block; /* Ensures that the image is not inline */
  position: relative; /* Relative position for absolute positioning of the logo */
}

.team-logo {
  width: 50px; /* Adjust logo size as needed */
  height: auto;
  position: absolute; /* Absolute position to overlay on the map image */
  bottom: 0; /* Position from the bottom of the image */
  left: 50%; /* Position from the left of the image */
  transform: translate(
    -50%,
    -75%
  ); /* Center the logo on the bottom of the image */
  z-index: 2; /* Ensures the logo is above the map image */
}

.map-text {
  font-size: 1em; /* Adjust font size as needed */
  text-transform: uppercase; /* Capitalize action text */
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background for text */
  width: 100%; /* Full width */
  position: absolute;
  text-align: center; /* Center text */
  bottom: 0; /* Align to bottom */
  left: 0; /* Align to left */
}
