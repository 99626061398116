:root {
  --background-color: var(--bs-dark-bg-subtle);
  --primary-text-color: black;
  --secondary-text-color: white;
  --nav-bg-color: var(--bs-dark);
  --tab-color: black;
  --mode-switch: #f15c25;
}

[data-theme="dark"] {
  --background-color: var(--bs-dark);
  --primary-text-color: #ffffff;
  --secondary-text-color: #000000;
  --nav-bg-color: #f15c25;
  --tab-color: white;
  --mode-switch: var(--bs-dark);
}

/* styling for profile-name text (TBD) */
.navbar-playername {
  font-weight: 600;
  font-size: 22px;
  color: var(--primary-text-color);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
}

.navbar-rightside {
  margin-right: 8px;
}

li .MuiSvgIcon-root {
  width: 0.9em;
  height: 0.9em;
  margin: 8px 4px;
  padding: 2px 0px;
}

.btn.btn-primary.profile-btn,
.btn.btn-primary.profile-btn:focus {
  background-color: var(--mode-switch) !important;
  border-color: var(--mode-switch) !important;
  color: white !important;
}

.btn.btn-primary.switcher {
  background-color: var(--mode-switch) !important;
  border-color: var(--mode-switch) !important;
}

.navbar-toggler,
.btn-primary {
  color: white !important;
  background-color: #f15c25 !important;
  border-color: #f15c25 !important;
}

.btn-primary:disabled {
  color: white !important;
  background-color: #f15c25 !important;
  border-color: #f15c25 !important;
  opacity: 0.5 !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: white !important;
  background-color: #e25623 !important;
  border-color: #e25623 !important;
}

.btn-primary.dropdown-toggle:active,
.btn-primary.dropdown-toggle.show {
  background-color: #f15c25 !important;
  border-color: #f15c25 !important;
}

/* Customizes the background color of the switch when it's checked */
.form-check-input:checked,
.form-check-input:active {
  background-color: #e25623 !important;
  border-color: #e25623 !important;
}

/* Customizes the handle of the switch */
.form-check-input:checked + .form-check-label::before,
.form-check-input:active + .form-check-label::before {
  background-color: #000000;
}

.dropdown .dropdown-menu .dropdown-item:active {
  background-color: #e25623;
}

.navbar .navbar-brand,
.navbar .navbar-brand:hover,
.nav-item .nav-link,
.nav-item .nav-link:active,
.nav-item .nav-link:focus {
  color: white;
}

.nav .nav-item .nav-link {
  background-color: #f15c25 !important;
  color: white;
}

.navbar {
  background-color: var(--nav-bg-color) !important;
}

.navlist .nav .nav-link {
  color: var(--tab-color);
}

.navlist .nav .nav-link.active,
.navlist .nav .nav-link:hover {
  background-color: #e25623;
  color: white;
}

.team-name {
  color: var(--primary-text-color);
}

.footer {
  background-color: var(--nav-bg-color);
  color: white;
}

.container .table,
.table {
  table-layout: auto; /* Allows the table to adjust column widths based on content */
  max-width: 100%; /* Prevents the table from becoming wider than the container */
}

.table th {
  background-color: #e25623 !important;
  color: white !important;
}

.table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table th.ranking {
  width: 40px; /* Set a fixed width */
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.table th.points {
  width: 100px !important;
  text-align: right;
}

.table th,
.table td {
  padding: 8px !important;
}

.background-color {
  background-color: var(--background-color);
}

.spinner-border.text-primary {
  border-color: transparent #f15c25 #f15c25 #f15c25 !important;
}

.container {
  color: var(--primary-text-color);
}
